<div class="head" *ngIf="!isExcludedUrl()">
  <!-- <button mat-raised-button *ngIf="url !== '/auth/login'" class="mr-5">
    <span>Logout</span>
    <mat-icon>power_settings_new</mat-icon>
 </button> -->
  <div class="col-md-11">
    <h2 class="text-primary">ViCAS ADMIN SERVER</h2>
  </div>

  <div class="col-md-1 mr-5 logout">
    <p style="color: #007BFF;" class="p-2 cursor-pointer" (click)="logout()">Logout</p>
  </div>
</div>

<div class="row">
    <div class="col-md-12">
        <div class="p-3">
            <!-- <div *ngIf="url !== '/auth/login'">
                <p style="color: #007BFF;" class="float-right p-2 cursor-pointer" (click)="logout()">Logout</p>
            </div> -->
            <router-outlet></router-outlet>
        </div>
    </div>
</div> 
