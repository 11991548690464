import { AuthGuard } from './core/guards/auth.guard';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [{
  path: 'auth',
  loadChildren: () =>
    import('@app/routes/authentication/authentication.module').then(
      m => m.AuthenticationModule
    )
},
{
  path: 'dashboard',
  loadChildren: () =>
    import('@app/routes/dashboard/dashboard.module').then(
      m => m.DashboardModule
    ),
},
{
  path: 'manage-device',
  loadChildren: () =>
    import('@app/routes/manage-device/manage-device.module').then(
      m => m.ManageDeviceModule
    ),
},
{
  path: 'manage-entity',
  loadChildren: () =>
    import('@app/routes/manage-entity/manage-entity.module').then(
      m => m.ManageEntityModule
    ),
},
{
  path: 'manage-application',
  loadChildren: () =>
    import('@app/routes/manage-application/manage-application.module').then(
      m => m.ManageApplicationModule
    ),
},
{
  path: 'old-status',
  loadChildren: () =>
    import('@app/routes/old-status/old-status.module').then(
      m => m.OldStatusModule
    ),
},
{
  path: 'search-job',
  loadChildren: () =>
    import('@app/routes/search-job/search-job.module').then(
      m => m.SearchJobModule
    ),
},
{
  path: '',
  redirectTo: 'auth',
  pathMatch: 'full'
},
{
  path: '**',
  redirectTo: 'auth'
}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
