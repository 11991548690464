import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Socket } from 'ngx-socket-io';
import { map } from 'rxjs/operators';


@Injectable()
export class WebSocketService {

    constructor(private socket: Socket) { }

    listen(eventname: string) {
        return this.socket
             .fromEvent(eventname)
             .pipe(map((data) => data));
    }

    emit(eventname: string, data: any) {
        this.socket.emit(eventname, data);
    }
}