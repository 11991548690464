import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../environments/environment'


const baseURL = environment['baseURL'];

@Injectable({
  providedIn: 'root'
})
export class ApiCallsService {

  constructor(private http: HttpClient) { }

  /* Method which makes API call to get the Task Response*/
  getTaskResponse(): Observable<any> {
    return this.http.get(`${baseURL}taskResponse`).pipe(
        map(
          (response: any) => {
            return response;
          },
          err => {
            console.log(err);
          }
        )
      );
  }

}