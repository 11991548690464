import { Component, OnInit } from '@angular/core';
import { Input } from '@angular/core';
import { Router } from '@angular/router';
import { NavigationEnd } from '@angular/router';
// import { WebSocketService } from './web-socket.service';
import { ApiCallsService } from './api-calls.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { filter } from 'rxjs/operators';

const baseURL = environment['baseURL'];

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  newMessage: string;
  messageList: string[] = [];
  url: string;
  // title = 'vicas-admin';
  title = 'Websocket Angular client ';
  userName: string;
  message: string;
  output: any[] = [];
  feedback: string;
  taskResponse: any = [];
  isPermitted: boolean = false;
  devices: any = [];
  taskLists: any = [];
  newTasks: any = [];
  reservered: any = [];
  currentUrl: string = '';

  constructor(
    readonly apiCallsService: ApiCallsService,
    readonly router: Router,
    private http: HttpClient
  ) {}

  ngOnInit() {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.currentUrl = event.urlAfterRedirects;
      });
    // this.webSocketService.listen('typing').subscribe((data) => this.updateFeedback(data));
    // this.webSocketService.listen('chat').subscribe((data) => this.updateMessage(data));

    // this.getTaskResponse();
    setInterval(() => {
      // this.getTaskResponse();
    }, 60000);
    // this.taskResponse = this.getTaskResponse();

    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.getUrl();
        this.isPermitted = true;
      }
    });

    this.url = this.router.url;
  }
  isExcludedUrl(): boolean {
    const excludedUrls = [
      '/auth/login',
      '/auth/forgot-password',
      '/auth/reset-password',
    ];
    return excludedUrls.includes(this.currentUrl);
  }
  // getTaskResponse() {
  //   this.apiCallsService.getTaskResponse().subscribe(data => {
  //     this.taskResponse = data;
  //     this.reservered = data;

  //     this.devices = [...new Set(this.taskResponse.map(item => item.deviceName))];
  //     this.taskLists = [...new Set(this.taskResponse.map(item => item.taskType))];

  //   }, err => {
  //     console.log(err);
  //   }

  //   );
  // }

  filterByDevice(e) {
    this.taskResponse = this.reservered;

    this.newTasks = [];

    this.taskResponse.forEach((item) => {
      if (item['deviceName'].includes(e.target.value)) {
        this.newTasks.push(item);
      }
    });

    this.taskResponse = this.newTasks;
  }

  filterByTask(e) {
    this.taskResponse = this.reservered;

    this.newTasks = [];

    this.taskResponse.forEach((item) => {
      if (item['taskType'].includes(e.target.value)) {
        this.newTasks.push(item);
      }
    });

    this.taskResponse = this.newTasks;
  }

  getUrl() {
    this.url = this.router.url;
  }

  logout() {
    localStorage.removeItem('Authorization');
    //this.router.navigate(['account/login']);
    this.router.navigate(['auth/login']);
  }

  // messageTyping(): void {
  //   this.webSocketService.emit('typing', this.userName);
  // }

  // sendMessage(): void {
  //   this.webSocketService.emit('chat', {
  //     message: this.message,
  //     handle: this.userName
  //   });
  //   this.message = "";
  // }

  // updateMessage(data:any) {
  //   this.feedback = '';
  //   if(!!!data) return;
  //   this.output.push(data);
  // }

  // updateFeedback(data: any){
  //   this.feedback = `${data} is typing a message`;
  // }
}
